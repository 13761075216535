.modal-dialog .modal-content {
  background: #f7f7f7;
  backdrop-filter: blur(10px); }
  .modal-dialog .modal-content .modal-header {
    border-bottom: 1px solid #866ca3; }
  .modal-dialog .modal-content .modal-body .captcha {
    margin-top: 20px;
    width: 100%; }
  .modal-dialog .modal-content .modal-body .disabled-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px; }
    .modal-dialog .modal-content .modal-body .disabled-div .error {
      color: red;
      margin-left: 20px; }
  .modal-dialog .modal-content .modal-body .text-field {
    background: #f3f3f3; }

.root {
  text-align: center;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto; }
  .root .header {
    font-weight: normal;
    font-size: 32pt; }
    .root .header .header-image {
      margin-top: 15px; }
      @media screen and (max-width: 1050px) {
        .root .header .header-image {
          width: 60%; } }
    .root .header .header-title-container {
      font-weight: bold;
      letter-spacing: 5px;
      font-size: 75px;
      line-height: 75px; }
      .root .header .header-title-container .header-title-blue {
        top: -75px;
        margin-bottom: -75px; }
      @media screen and (max-width: 1050px) {
        .root .header .header-title-container {
          font-size: 35px;
          line-height: 35px;
          letter-spacing: 3px; }
          .root .header .header-title-container .header-title-blue {
            top: -35px;
            margin-bottom: -35px; } }
  .root .logo-div {
    position: relative; }
    .root .logo-div .logo {
      position: fixed;
      max-width: 150px;
      height: auto;
      display: table;
      top: 0;
      right: 0;
      z-index: 100; }
      @media screen and (max-width: 1050px) {
        .root .logo-div .logo {
          display: none; } }
  .root .subheader {
    margin-top: 50px;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 1050px) {
      .root .subheader {
        flex-direction: column;
        align-items: center; } }
    .root .subheader .left, .root .subheader .right {
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 10px;
      background-color: #f3f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%; }
      @media screen and (max-width: 1050px) {
        .root .subheader .left, .root .subheader .right {
          width: 60%; } }
      @media screen and (max-width: 760px) {
        .root .subheader .left, .root .subheader .right {
          width: 80%; } }
      .root .subheader .left .fa, .root .subheader .right .fa {
        font-size: 50px;
        color: #bb2221; }
      .root .subheader .left .title, .root .subheader .right .title {
        font-weight: bold;
        font-size: 22pt;
        margin-top: 10px; }
      .root .subheader .left .text, .root .subheader .right .text {
        margin-top: 10px;
        width: 80%; }
        @media screen and (max-width: 1050px) {
          .root .subheader .left .text, .root .subheader .right .text {
            width: 70%; } }
        .root .subheader .left .text a, .root .subheader .right .text a {
          color: inherit; }
    .root .subheader .right {
      margin-left: 10%; }
      @media screen and (max-width: 1050px) {
        .root .subheader .right {
          margin-top: 20px;
          margin-left: 0; } }
  .root .books {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
    .root .books .book {
      cursor: pointer;
      position: relative;
      margin-bottom: 20px;
      height: auto;
      position: relative; }
      .root .books .book .label {
        display: none;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        justify-content: center; }
        .root .books .book .label .top-label {
          font-weight: bold; }
          .root .books .book .label .top-label a {
            color: inherit; }
        .root .books .book .label .bottom-label {
          margin-top: 5px; }
        @media screen and (min-width: 1440px) {
          .root .books .book .label {
            display: flex; } }
    .root .books.finished {
      display: flex;
      flex-direction: column;
      height: 300px;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 23pt;
      border-radius: 8px;
      background-color: #f7f7f7; }
    .root .books .carousel-slider {
      overflow: visible; }
      .root .books .carousel-slider .control-arrow {
        background: inherit !important; }
  .root .footer {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    margin-top: 20px;
    background-color: #f3f3f3;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .root .footer .footer-text {
      text-align: left; }
    .root .footer a {
      color: inherit; }
  .root.thankyou {
    height: 100vh;
    display: block; }
    .root.thankyou .thankyou-overhead {
      display: flex;
      justify-content: center;
      flex-direction: row; }
      .root.thankyou .thankyou-overhead .thankyou-block {
        margin-top: 80px;
        width: 30%;
        background-color: #1d0064;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white; }
        @media screen and (max-width: 1050px) {
          .root.thankyou .thankyou-overhead .thankyou-block {
            width: 60%; } }
        @media screen and (max-width: 760px) {
          .root.thankyou .thankyou-overhead .thankyou-block {
            width: 80%; } }
        .root.thankyou .thankyou-overhead .thankyou-block .fa {
          font-size: 50px;
          color: #866ca3; }
        .root.thankyou .thankyou-overhead .thankyou-block .title {
          font-weight: bold;
          font-size: 22pt;
          margin-top: 10px; }
        .root.thankyou .thankyou-overhead .thankyou-block .text {
          margin-top: 10px;
          width: 80%; }
          @media screen and (max-width: 1050px) {
            .root.thankyou .thankyou-overhead .thankyou-block .text {
              width: 70%; } }
          .root.thankyou .thankyou-overhead .thankyou-block .text a {
            color: inherit; }
  .root.admin {
    height: 100vh;
    display: block; }
    .root.admin .logged-out {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center; }
      .root.admin .logged-out .login-block {
        width: 30%;
        background-color: #f3f3f3;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 1050px) {
          .root.admin .logged-out .login-block {
            width: 60%; } }
        @media screen and (max-width: 760px) {
          .root.admin .logged-out .login-block {
            width: 80%; } }
        .root.admin .logged-out .login-block .login-email, .root.admin .logged-out .login-block .login-password {
          width: 80%; }
    .root.admin .logged-in {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center; }
      .root.admin .logged-in .admin-block {
        width: 80%;
        background-color: #f3f3f3;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 1050px) {
          .root.admin .logged-in .admin-block {
            width: 60%; } }
        @media screen and (max-width: 760px) {
          .root.admin .logged-in .admin-block {
            width: 80%; } }
        .root.admin .logged-in .admin-block .header-block {
          height: 200px;
          width: 100%;
          display: flex;
          align-items: flex-start; }
          .root.admin .logged-in .admin-block .header-block .stats-block {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            width: calc(75% - 15px);
            padding-left: 60px;
            padding-top: 10px; }
            .root.admin .logged-in .admin-block .header-block .stats-block.loading {
              justify-content: center;
              align-items: center; }
          .root.admin .logged-in .admin-block .header-block .logout-block {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            width: calc(25% - 20px);
            margin-right: 20px;
            margin-top: 5px; }
            .root.admin .logged-in .admin-block .header-block .logout-block .logout-block-year {
              margin-top: 5px;
              display: flex;
              flex-direction: column; }
            .root.admin .logged-in .admin-block .header-block .logout-block .logout-block-date {
              margin-top: 5px;
              display: flex;
              flex-direction: column; }
              .root.admin .logged-in .admin-block .header-block .logout-block .logout-block-date label {
                display: block;
                margin: .4rem 0; }
        .root.admin .logged-in .admin-block .table-block {
          width: 100%; }
          .root.admin .logged-in .admin-block .table-block .ReactTable .rt-tbody {
            display: block;
            overflow: auto;
            height: 400px; }
        .root.admin .logged-in .admin-block .winner-block {
          margin: 30px 0; }

@font-face {
  font-family: "IroSans";
  src: url(../fonts/IroSans-Regular.otf) format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'IroSans';
  src: url(../fonts/IroSans-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'IroSans';
  src: url(../fonts/IroSans-Slanted.otf) format("opentype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'IroSans';
  src: url(../fonts/IroSans-BoldSlanted.otf) format("opentype");
  font-weight: 700;
  font-style: italic; }

body {
  position: relative;
  background: #100036;
  margin: 0;
  padding: 0;
  font-family: IroSans, sans-serif; }
